

































import Dialog from '@/calendesk/models/Dialog'
import DialogSize from '@/calendesk/models/DialogSize'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
export default Vue.extend({
  name: 'AppFooterAccount',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dialogTypes: DialogTypes,
      dialogSize: DialogSize
    }
  },
  computed: {
    ...mapGetters({
      isUserLogged: 'user/isUserLogged'
    }),
    menuList (): Record<string, any> {
      return [
        {
          name: this.data.configuration.wb_account_title__text__,
          key: 'footer-menu3',
          items: [
            {
              title: this.$trans('login'),
              type: DialogTypes.LOGIN,
              show: !this.isUserLogged,
              key: 'footer-log_in'
            },
            {
              title: this.$trans('sign_up'),
              type: DialogTypes.SIGN_UP,
              show: !this.isUserLogged,
              key: 'footer-register'
            },
            {
              title: this.$trans('bookings'),
              path: { name: 'bookings' },
              show: this.isUserLogged,
              key: 'footer-bookings'
            },
            {
              title: this.$trans('payments'),
              path: { name: 'payments' },
              show: this.isUserLogged,
              key: 'footer-payments'
            },
            {
              title: this.$trans('profile'),
              path: { name: 'account' },
              show: this.isUserLogged,
              key: 'footer-my-account'
            }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      openDialog: 'dialog/openDialog',
      openDialog2: 'dialog/openDialog2'
    }),
    openDialogHandle (menuItem: Record<string, any>): void {
      if (menuItem.type) {
        if (menuItem.dialogType === DialogTypes.LOGIN || menuItem.dialogType === DialogTypes.SIGN_UP) {
          this.openDialog2(new Dialog(true, menuItem.type, menuItem.title))
        } else {
          this.openDialog(new Dialog(true, menuItem.type, menuItem.title))
        }
      }
    }
  }
})
